import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link } from 'react-router-dom';
import Sidebar from '../Sidebar';
import { IoReturnDownBack } from "react-icons/io5";
import ProductBar from '../ProductBar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const CreateProduct = () => {
    const navigate = useNavigate()
    const [loading,setIsLoading]=useState(false)
    const [modal,setModal]=useState(false)
    const [categories,setCategories] =useState([])
    const [name,setName]=useState('')
    const [category,setCategory]=useState('')
    const [image,setImage]=useState(null)
    const [description,setDescription]=useState('')
    
    const [unitOfMeasure,setUnitOfMeasure]=useState('')
    
    const [cname,setCName]=useState('')
    const [cDescription,setCDescription]=useState('')

    const [cimage,setCImage]=useState(null)

  
 
const token = localStorage.getItem('token')


useEffect(()=>{
if(!token){
    navigate('/')
}
},[token])
const handleTextEditor = (content, delta, source, editor) => {

    setDescription(content);
};
//create category section






//add category

const addCategory = async ()=> {
    if(cname==""){
        toast.error("please enter name", {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
  
    else {
        const formData = new FormData();
        if(cname){
            formData.append("name",cname)
        }
        if(cDescription){
            formData.append('description',cDescription)
        }
        
      
        if(cimage){
            formData.append('image',cimage)
        }
  
       
        setIsLoading(true);
        const result = await Services.createCategory(formData)
        console.log(result)
        if (result?.status === 200) {
       
        toast.success("Category added sucessfuly", {
            autoClose: 1000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
          getCategoriesList()
          setModal(false)
          setCImage(null)
          setCName('')
          setCDescription('')
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    }
}

//handle

const handlePhotoCategory = (event) => {
    const selectedFile = event.target.files[0];
    setCImage(selectedFile);
  };
//handle category


 


 
    const addProduct = async (ispublis)=> {
        if(name==""){
            toast.error("please enter name", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
      
        else {
            const formData = new FormData();
            if(name){
                formData.append("name",name)
            }
            if(ispublis) {
                formData.append('is_publish',(ispublis == '1' || ispublis == '2') ? 1 : 0)
            }
            if(category){
                formData.append('category_id',category)
            }
            
          
            if(image){
                formData.append('image',image)
            }
            if((description || name)){
                formData.append('description',description ? description : name)
            }
            if(unitOfMeasure){
                formData.append('unit_of_measure',unitOfMeasure)
            }
        
          
      
         
            setIsLoading(true);
            const result = await Services.createProduct(formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success("Product added sucessfuly", {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              getCategoriesList()
              setName('')
              setUnitOfMeasure('')
              setCategory('')
           
              setDescription('')
              if(ispublis == 0){
                navigate('/draft-products')
              }
              else if (ispublis == 2) {
                navigate('/products')
              }
              else {
                getCategoriesList()
                setName('')
                setUnitOfMeasure('')
                setCategory('')
             
                setDescription('')
              }
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    }
    

    //get categories list
  
        const getCategoriesList = async ()=> {
            
                setIsLoading(true);
                const result = await Services.getCategories()
                
                if (result?.status === 200) {
                
               setCategories(result?.data?.data?.categories)
               console.log(result?.data?.data?.categories)
                }
                else {
                    toast.error(result?.data?.message, {
                        autoClose: 2000,
                        pauseOnHover: true,
                        draggable: true,
                        position: toast.POSITION.TOP_CENTER,
                      });
                }
                setIsLoading(false);
            }

            useEffect(()=>{
                getCategoriesList()
            },[])
    ///Brands functions and api calls

const handlePhoto = (event) => {
    const selectedFile = event.target.files[0];
    setImage(selectedFile);
  };
 


 
    


  return (
    <div className='create-company dark-theme py-3'>
        <ProductBar />
    <Link to='/dashboard' className="back">
        <IoReturnDownBack  /> Go to dashboard
    </Link>
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}

    {modal && <div className='modal-fixed'>
        <div className="form-modal">
            <div className="icon">
            <i class="fa-regular fa-circle-xmark" onClick={()=>setModal(false)}></i>
            </div>
            <p className="title">
                Create Category
            </p>

            <div className="form-wrapper">
      
     
         <div className="row">
            <div className="col-12 col-md-12">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={cname}  onChange={(e)=>setCName(e.target.value)}  />
                </div>
            </div>
        
            
            
           
         </div>
        
         <div className="text-center mt-3">
            <button className='button'onClick={()=>addCategory()} >Add now</button>
        </div>
    </div>
            </div></div>}

<div className="container-fluid">
    <div className="row">
       
        <div className="col-12 col-md-12">
        <div className="create-company">
    <div className="form-wrapper">
         <h2 className="title-small text-center">Design Product</h2>
     
         <div className="row">
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Product Name</label>
                <input type="text" className='input' value={name}  onChange={(e)=>setName(e.target.value)}  />
                </div>
            </div>
           
            
            {/* <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Photo</label>
                <input type="file" accept=".jpg, .jpeg, .png, .gif" className='input'   onChange={handlePhoto}   />
                </div>
            </div> */}
            <div className="col-12 col-md-6">
               
                <div className="form-group">
                <label htmlFor="">Category </label>
                <div className="categories-wrapper">
                <select className='input' value={category} onChange={(e)=>setCategory(e.target.value)}>
                <option value="">Please select</option>
                    {categories?.map((cat)=> <option key={cat?.id} value={cat?.id}>{cat?.name}</option>)}
                </select>
              <button onClick={()=>setModal((pre)=> !pre)}>Add category</button>
                </div>
                
                </div>
               
            </div>
           
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Unit of measure</label>
                <select className='input' value={unitOfMeasure} onChange={(e)=>setUnitOfMeasure(e.target.value)}>
                <option value="">Please select</option>
                    <option value="KG">KG</option>
                    <option value="PKT">PKT</option>
                    <option value="PC">PC</option>
                    <option value="PC's">PC's</option>
                    <option value="NOS">NOS</option>
                    <option value="FT">FT</option>
                    <option value="MTR">MTR</option>
                    <option value="SQFT">SQFT</option>
                    <option value="SQMT">SQMT</option>
                    <option value="g">g</option>
                    <option value="Gm">Gm</option>
                    <option value="MM">MM</option>
                    <option value="Inch">Inch</option>
                    
                </select>
                </div>
            </div>
            <div className="col-12">
              
                <div className="form-group">
               <label htmlFor="">Required technical  specifications </label>
               
               <ReactQuill  value={description} onChange={handleTextEditor} />
            
               </div>
            </div>
        </div>
        
        
         <div className="text-center d-flex gap-4 justify-content-center ">
            <button className='button ml-2' onClick={()=>addProduct('1')} >Save & New</button >
            <button className='button ml-2' onClick={()=>addProduct('0')} >Save As Draft</button>
            <button className='button ml-2' onClick={()=>addProduct('2')} >Save & Exit</button>
        </div>
    </div>
 </div>
        </div>
    </div>
</div>

    </div>
  )
}

export default CreateProduct