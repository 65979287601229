import React ,{useState,useEffect} from 'react'
import { Services } from '../../api/Services';
import { useNavigate ,Link,useParams} from 'react-router-dom';
import ProductBar from '../ProductBar';
import { IoReturnDownBack } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateCategory = () => {
const {id}=useParams()
    const naviage = useNavigate();
    const [name,setName]=useState('')
    const [loading,setIsLoading]=useState(false)
    //get category detail 
    const getCategoryDetailFunction = async (id) => {
        setIsLoading(true);
        const result = await Services.categoryDetail(id);
        console.log(result);
        if (result?.status === 200) {
         
          setName(result?.data?.data?.category.name)
          
        } else {
          toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setIsLoading(false);
      };
      useEffect(() => {
        getCategoryDetailFunction(id);
      }, []);


    //add category

const updateCategoryApi = async ()=> {
    if(name==""){
        toast.error("please enter name", {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
  
    else {
        const formData = new FormData();
        if(name){
            formData.append("name",name)
        }
      
  
       
        setIsLoading(true);
        const result = await Services.updateCategory(id,formData)
        console.log(result)
        if (result?.status === 200) {
       
        toast.success("Category added sucessfuly", {
            autoClose: 1000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
         setTimeout(()=>{
            naviage('/categories')
         })
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    }
}
  return (
    <div className='create-company dark-theme py-3'>
    <ProductBar />
<Link to='/categories' className="back">
    <IoReturnDownBack  /> Go to categories
</Link>
<ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
<div className="container">
<div className="form-wrapper">
         <h2 className="title-small text-center">Update category</h2>
     
         <div className="row">
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={name}  onChange={(e)=>setName(e.target.value)}  />
                </div>
            </div>
            
            </div>
            <div className="text-center m-3 ">
            <button className='button'onClick={()=>updateCategoryApi()} >Update  now</button>
        </div>
            </div>
            </div>
</div>
  )
}

export default UpdateCategory