import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link, useParams } from 'react-router-dom';
import userAvatar from '../../images/user.png'

import { IoReturnDownBack } from "react-icons/io5";
import Origins from '../../helpers/Helpers'

const UpdateModel = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const [loading,setIsLoading]=useState(false)

    const [name,setName]=useState('')
   

    const [image,setImage]=useState(null)
   
    const [description,setDescription]=useState('')

    const [modelNumber,setModelNumber]=useState('')
 
const token = localStorage.getItem('token')


useEffect(()=>{
if(!token){
    navigate('/')
}
},[token])

//create category section
const getModelDetailApi = async (id) => {
    setIsLoading(true);
    const result = await Services.getModelDetail(id);
    console.log(result);
    if (result?.status === 200) {
     
      setName(result?.data?.data?.models?.name)
      setDescription(result?.data?.data?.models?.description)
      setModelNumber(result?.data?.data?.models?.model_number)
    } else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getModelDetailApi(id);
  }, [id]);

   



 
  


const handlePhoto = (event) => {
    const selectedFile = event.target.files[0];
    setImage(selectedFile);
  };
 


 
    const updateModelApi = async ()=> {
        if(name==""){
            toast.error("please enter name", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        else if (modelNumber === ''){
            toast.error("please enter Model number", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
            });
        }
      
        else {
            const formData = new FormData();
            if(name){
                formData.append("name",name)
            }
            if(modelNumber){
                formData.append('model_number',modelNumber)
            }
            
          
            if(image){
                formData.append('image',image)
            }
            if(description){
                formData.append('description',description)
            }
           
           
            setIsLoading(true);
            const result = await Services.updateModel(id,formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success("Model updated sucessfuly", {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              setTimeout(()=>{
                navigate('/models')
              },1500)
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    }
    


  return (
    <div className='create-company dark-theme py-3'>
   
    <Link to='/models' className="back">
        <IoReturnDownBack  /> Go to Models
    </Link>
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}

   
<div className="container-fluid">
    <div className="row">
       
        <div className="col-12 col-md-12">
        <div className="create-company">
    <div className="form-wrapper">
         <h2 className="title-small text-center">Update Model</h2>
     
         <div className="row">
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={name}  onChange={(e)=>setName(e.target.value)}  />
                </div>
            </div>
           
            
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Image</label>
                <input type="file" accept=".jpg, .jpeg, .png, .gif" className='input'   onChange={handlePhoto}   />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Model Number</label>
                <input type="text" className='input' value={modelNumber}  onChange={(e)=>setModelNumber(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Description</label>
                <input type="text" className='input' value={description}  onChange={(e)=>setDescription(e.target.value)}  />
                </div>
            </div>
          
           
         </div>
        
         <div className="text-center mt-3">
            <button className='button'onClick={()=>updateModelApi()} >Update now</button>
        </div>
    </div>
 </div>
        </div>
    </div>
</div>

    </div>
  )
}

export default UpdateModel