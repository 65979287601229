import React, { useEffect, useState } from "react";
import { Services } from "../../api/Services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams,Link } from "react-router-dom";
import User from "../../images/user.png";

import { IoReturnDownBack } from "react-icons/io5";
import ProductBar from '../ProductBar';
import userAvatar from "../../images/user.png";
const InventoryDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({});

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);
 
  const getinventory = async (id) => {
    setIsLoading(true);
    const result = await Services.getInventoryDetail(id);
    console.log(result);
    if (result?.status === 200) {
     console.log(result)
     setProduct(result?.data?.data)
     
    } else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getinventory(id);
  }, []);
  return (
    <div className='create-company dark-theme py-3'>
    <ProductBar />
<Link to='/inventories' className="back">
    <IoReturnDownBack  /> Go to Inventory list
</Link>
      <ToastContainer />
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
       
          <div className="col-12 col-md-12">
            <div className="container company-details-wrapper pt-4">
             
             

              <h2 className="title-main">Inventory details</h2>
              <div className="row mb-3">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Inventory Id</p>
                    <p className="value">{product?.id}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Product Type</p>
                    <p className="value">{product?.product_type}</p>
                  </div>
                </div>
               {product?.unit_cost &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Unit cost</p>
                    <p className="value">{product?.unit_cost}</p>
                  </div>
                </div>}
                {product?.minimum_stock &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Minimum stock</p>
                    <p className="value">{product?.minimum_stock}</p>
                  </div>
                </div>}
                {product?.currency &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Currency</p>
                    <p className="value">{product?.currency}</p>
                  </div>
                </div>}


                {product?.exchange_rate &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Exchange rate</p>
                    <p className="value">{product?.exchange_rate}</p>
                  </div>
                </div>}


                {product?.freight &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Freight</p>
                    <p className="value">{product?.freight}</p>
                  </div>
                </div>}
               

                {product?.custom_duties &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Custom duties</p>
                    <p className="value">{product?.custom_duties}</p>
                  </div>
                </div>}

                {product?.expiry_date &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Expiry date</p>
                    <p className="value">{product?.expiry_date}</p>
                  </div>
                </div>}


                {product?.expiry_date_alert &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Expiry date alert</p>
                    <p className="value">{product?.expiry_date_alert}</p>
                  </div>
                </div>}
                

                
                {product?.custom_hs_code &&  <div className="col-12 col-md-6 col-lg-4">
                  <div className="company-detail-item">
                    <p className="title">Custom HS Code</p>
                    <p className="value">{product?.custom_hs_code}</p>
                  </div>
                </div>}
                
              
         
           
          
             
               
              </div>

        
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryDetail;


