import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link } from 'react-router-dom';
import { CustomerServices } from '../../api/CustomerService';
import { CiEdit } from "react-icons/ci";
import { IoReturnDownBack } from "react-icons/io5";



const CreateVendor = () => {
    const navigate = useNavigate()
    const [loading,setIsLoading]=useState(false)
      const [tab,setTab]=useState('terms')
    const [name,setName]=useState('')
   

    const [address,setAddress]=useState('')
    const [headOfficeAddress,setHeadOfficeAddress]=useState('')
    const [phone1,setPhone1]=useState('')
    const [phone2,setPhone2]=useState('')
    const [phone3,setPhone3]=useState('')
    const [headOfficePhone1,setHeadOfficePhone1]=useState('')
    const [headOfficePhone2,setHeadOfficePhone2]=useState('')
    const [headOfficePhone3,setHeadOfficePhone3]=useState('')
    const [isRegistered,setIsRegistered]=useState('')
    const [isNTN,setIsNTN]=useState('')
    const [NTNNumber,setNTNNumber]=useState('')
    const [isSalexTaxReGistered,setIsSalexTaxReGistered]=useState('')
    const [salexTaxNumber,setSalesTaxNumber]=useState('')
    // busines tersm states
    const [creditDays,setCreditDays]=useState('')
    
    const [creditBussinessAmountLimit,setCreditBussinessAmountLimit]=useState('')
    const [creditOverdueAmountLimit,setCreditOverdueAmountLimit]=useState('')
    const [creditSalesAmountLimit,setCreditSalesAmountLimit]=useState('')
   //tax states

    //tax states
    const [incomeTaxWithHeldType,setIncomeTaxWithHeldType] = useState('')
    const [incomeTaxWithHeld,setIncomeTaxWithHeld] = useState('')
    const [incomeTaxWithHeldDate,setIncomeTaxWithHeldDate] = useState('')
   
    const [manualIncomeTaxWithHeld,setMannualIncomeTaxWithHeld] =useState('')
    const [manualIncomeTaxWithHeldDate,setMannualIncomeTaxWithHeldDate] =useState('')
    const [salesWithHeldType,setSalesWithHeldType] = useState('')
    const [salesWithHeld,setSalesWithHeld] = useState('')
    const [salesWithHeldDate,setSalesWithHeldDate] = useState('')
    const [manualSalesWithHeldTax,setMannualSalesWithHeldTax] =useState('')
    const [manualSalesWithHeldDate,setMannualSalesWithHeldDate] =useState('')
const token = localStorage.getItem('token')


useEffect(()=>{
if(!token){
    navigate('/')
}
},[token])




   


//get taxes apis functions
//income tax api call
const incomeTaxDetailApi = async ()=> {
     
      
        
    setIsLoading(true)


   
  
    const result = await Services.getWithHoldingTaxIcomeDetail()
    console.log(result)
    if (result?.status === 200) {
   setIncomeTaxWithHeld(result?.data?.data?.with_holding_taxes_ratios[0].tax_ratio)
   setIncomeTaxWithHeldDate(result?.data?.data?.with_holding_taxes_ratios[0].applied_from)
   console.log(result)
   
    }
    else {
        toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
    setIsLoading(false);

}

useEffect(()=>{
incomeTaxDetailApi()
},[])
 
      //sales api calls
      const salesTaxDetailApi = async ()=> {
     
      
        
        setIsLoading(true)
   

       
      
        const result = await Services.getWithHoldingTaxSalesDetail()
        console.log(result)
        if (result?.status === 200) {
       setSalesWithHeld(result?.data?.data?.with_holding_taxes_ratios[0].tax_ratio)
       setSalesWithHeldDate(result?.data?.data?.with_holding_taxes_ratios[0].applied_from)
       console.log(result)
       
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    
}

useEffect(()=>{
    salesTaxDetailApi()
},[])



 //add vendor api


 
    const addVendorApi = async ()=> {
        if(name==""){
            toast.error("please enter name", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
      
      
        else {
            const formData = new FormData();
            if(name){
                formData.append("name",name)
            }
            if(address){
                formData.append("address",address)
            }
            if(headOfficeAddress){
                formData.append("head_office_address",headOfficeAddress)
            }
           
            if(phone1){
                formData.append("phone_1",phone1)
            }
            if(phone2){
                formData.append("phone_2",phone2)
            }
            if(phone3){
                formData.append("phone_3",phone3)
            }

            if(headOfficePhone1){
                formData.append("head_office_phone_1",headOfficePhone1)
            }
            if(headOfficePhone2){
                formData.append("head_office_phone_2",headOfficePhone2)
            }
            if(headOfficePhone3){
                formData.append("head_office_phone_3",headOfficePhone3)
            }
            if(isRegistered) {
                
                formData.append("vendor_type",isRegistered)
            }
           if(isSalexTaxReGistered){
            formData.append('sales_tax_register',isSalexTaxReGistered)
           }
           if(isNTN){
            formData.append('register_in_ntn',isNTN)
           }
           if(incomeTaxWithHeldType){
            formData.append("income_tax_with_held",incomeTaxWithHeldType)
           }
           if(salesWithHeldType){
            formData.append("sales_tax_with_held",salesWithHeldType)
           }

            if(NTNNumber){
                formData.append("ntn_number",NTNNumber)
            }
            if(salexTaxNumber){
                formData.append("sales_tax_number",salexTaxNumber)
            }
            if(creditDays){
                formData.append("credit_days",creditDays)
            }
            if(creditBussinessAmountLimit){
                formData.append("credit_business_amount_limit",creditBussinessAmountLimit)
            }
            if(creditOverdueAmountLimit){
                formData.append("credit_overdue_amount_limit",creditOverdueAmountLimit)
            }
            if(creditSalesAmountLimit){
                formData.append("credit_sales_amount_limit",creditSalesAmountLimit)
            }
           
             if((incomeTaxWithHeldType == 'tax' && (isRegistered == 'tax_register') && (isSalexTaxReGistered == '1') )){
                if(incomeTaxWithHeld){
                    formData.append("income_tax_with_held_ratio",incomeTaxWithHeld)
                }
                if(incomeTaxWithHeldDate){
                    formData.append("income_tax_with_held_applicable_date",incomeTaxWithHeldDate)
                }
             }
             if(incomeTaxWithHeldType == 'manual'){
                if(manualIncomeTaxWithHeld){
                    formData.append("income_tax_with_held_ratio",manualIncomeTaxWithHeld)
                }
                if(manualIncomeTaxWithHeldDate){
                    formData.append("income_tax_with_held_applicable_date",manualIncomeTaxWithHeldDate)
                }
             }



             if((salesWithHeldType == 'tax' && (isRegistered == 'tax_register') && (isSalexTaxReGistered == '1') )){
                if(salesWithHeld){
                    formData.append("sales_tax_with_held_ratio",salesWithHeld)
                }
                if(salesWithHeldDate){
                    formData.append("sales_tax_with_held_applicable_date",salesWithHeldDate)
                }
             }
             if(salesWithHeldType == 'manual'){
                if(manualSalesWithHeldTax){
                    formData.append("sales_tax_with_held_ratio",manualSalesWithHeldTax)
                }
                if(manualSalesWithHeldDate){
                    formData.append("sales_tax_with_held_applicable_date",manualSalesWithHeldDate)
                }
             }
           
          
           
        
          
            
           
          
        
            
          
          
           
           
            setIsLoading(true);
            const result = await CustomerServices.addVendor(formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success("Vendor added sucessfuly", {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              setTimeout(()=>{
                navigate('/vendors')
              },1500)
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    }
    


  return (
    <div className='create-company dark-theme py-3'>
   
    <Link to='/vendors' className="back">
        <IoReturnDownBack  /> Go to vendors
    </Link>
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}

   
<div className="container-fluid">
    <div className="row">
       
        <div className="col-12 col-md-12">
        <div className="create-company">
    <div className="form-wrapper">
         <h2 className="title-small text-center">Create Vendor</h2>
     
         <div className="row">
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={name}  onChange={(e)=>setName(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Address</label>
                <input type="text" className='input' value={address}  onChange={(e)=>setAddress(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Phone 1</label>
                <input type="text" className='input' value={phone1}  onChange={(e)=>setPhone1(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Phone 2</label>
                <input type="text" className='input' value={phone2}  onChange={(e)=>setPhone2(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Phone 3</label>
                <input type="text" className='input' value={phone3}  onChange={(e)=>setPhone3(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Head Office Address</label>
                <input type="text" className='input' value={headOfficeAddress}  onChange={(e)=>setHeadOfficeAddress(e.target.value)}  />
                </div>
            </div>
          
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Head office phone 1</label>
                <input type="text" className='input' value={headOfficePhone1}  onChange={(e)=>setHeadOfficePhone1(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Head office phone 2</label>
                <input type="text" className='input' value={headOfficePhone2}  onChange={(e)=>setHeadOfficePhone2(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Head office phone 3</label>
                <input type="text" className='input' value={headOfficePhone3}  onChange={(e)=>setHeadOfficePhone3(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Vendor Type</label>
                <select className='input' value={isRegistered} onChange={(e)=>setIsRegistered(e.target.value)}>

                    <option value="">Select</option>
                    <option value="tax_register">Tax Register</option>
                    <option value="not_register">Not Registered</option>

                </select>
                </div>
            </div>

            {isRegistered == 'tax_register' &&  <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Select if Registered in NTN</label>
                <select className='input' value={isNTN} onChange={(e)=>setIsNTN(e.target.value)}>

                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>

                </select>
                </div>
            </div>}

            {((isRegistered == 'tax_register') && (isNTN == '1')) &&  <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor=""> NTN Number</label>
                <input type="text" className='input' value={NTNNumber}  onChange={(e)=>setNTNNumber(e.target.value)}  />
                </div>
            </div>}
           
            {isRegistered == 'tax_register' &&  <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Select if Vendor Salex Tax Registered</label>
                <select className='input' value={isSalexTaxReGistered} onChange={(e)=>setIsSalexTaxReGistered(e.target.value)}>

                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>

                </select>
                </div>
            </div>}

            {((isRegistered == 'tax_register') && (isSalexTaxReGistered== '1')) &&  <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Sales Tax Number</label>
                <input type="text" className='input' value={salexTaxNumber}  onChange={(e)=>setSalesTaxNumber(e.target.value)}  />
                </div>
            </div>}
            
           
          
           
         </div>

         <div className="term-tab">
        <button className={`button ${tab == 'terms' ? 'active' : ''}`} onClick={()=>setTab('terms')}>
            Business Terms
        </button>
        {((isRegistered  == 'tax_register') && (isNTN == '1') && (isSalexTaxReGistered == '1') &&  <button className={`button ${tab == 'tax' ? 'active' : ''}`} onClick={()=>setTab('tax')}>
            Taxations
        </button>) }
       
    </div>
  {/* Busines tersm fiels */}
    {tab == 'terms' &&    <div className="row">
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Credit Days</label>
                <input type="text" className='input' value={creditDays}  onChange={(e)=>setCreditDays(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Credit Bussiness Amount Limit</label>
                <input type="text" className='input' value={creditBussinessAmountLimit}  onChange={(e)=>setCreditBussinessAmountLimit(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Credit Overdue Amount Limi</label>
                <input type="text" className='input' value={creditOverdueAmountLimit}  onChange={(e)=>setCreditOverdueAmountLimit(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Credit Sales Amount Limi</label>
                <input type="text" className='input' value={creditSalesAmountLimit}  onChange={(e)=>setCreditSalesAmountLimit(e.target.value)}  />
                </div>
            </div>


            </div>
            
            }
        

        {tab == 'tax' &&    <div className="row">
        <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Select Incomewitheld Tax or manual</label>
                <select className='input' value={incomeTaxWithHeldType} onChange={(e)=>setIncomeTaxWithHeldType(e.target.value)}>

                    <option value="">Select</option>
                    <option value="tax">Tax</option>
                    <option value="manual">Mannual</option>

                </select>
                </div>
            </div>
         {/* Manual for withholding tax */}
            {incomeTaxWithHeldType == 'manual' && <div className="row"> <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                     Incomewitheld Tax  Ration
                    </span>
                   
                </div>
                <input type="text" className='input' value={manualIncomeTaxWithHeld} placeholder='Tax ratio' onChange={(e)=>setMannualIncomeTaxWithHeld(e.target.value)}  />
                </div>
            </div>

              <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Incomewitheld Tax Applicable date
                    </span>
                    
                </div>
                <input type="date" className='input' value={manualIncomeTaxWithHeldDate} placeholder='Tax ratio' onChange={(e)=>setMannualIncomeTaxWithHeldDate(e.target.value)}  />
                </div>
            </div>
            </div>
            
            }

            
          <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Select  Saleswitheld Tax or manual</label>
                <select className='input' value={salesWithHeldType} onChange={(e)=>setSalesWithHeldType(e.target.value)}>

                    <option value="">Select</option>
                    <option value="tax">Tax</option>
                    <option value="manual">Mannual</option>

                </select>
                </div>
            </div>

             {/* Manual forSaleswitheld Tax */}

            {salesWithHeldType == 'manual' && <div className="row"> <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Saleswitheld Taxx  Ratio
                    </span>
                    
                </div>
                <input type="text" className='input' value={manualSalesWithHeldTax} placeholder='Tax ratio' onChange={(e)=>setMannualSalesWithHeldTax(e.target.value)}  />
                </div>
            </div>

             <div className="col-12">
                <div className="form-group">
                <div className="label-flex">
                    <span className="text">
                    Saleswitheld Tax Applicable date
                    </span>
                    
                </div>
                <input type="date" className='input' value={manualSalesWithHeldDate} placeholder='Tax ratio' onChange={(e)=>setMannualSalesWithHeldDate(e.target.value)}  />
                </div>
            </div>
            </div>
            
            }


            </div>
            
            }
         <div className="text-center mt-3">
            <button className='button'onClick={()=>addVendorApi()} >Add now</button>
        </div>
    </div>
 </div>
        </div>
    </div>

   
</div>

    </div>
  )
}

export default CreateVendor