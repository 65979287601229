import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link ,useParams} from 'react-router-dom';
import { AuthService } from '../../api/AuthService';
import { IoReturnDownBack } from "react-icons/io5";
import Sidebar from '../Sidebar';

const UpdateUser = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const [loading,setIsLoading]=useState(false)
    const [email,setEmail]=useState('')
    const [user, setUser] = useState({});
    const [password,setPassword]=useState('')
    const [firstName,setFirstName]=useState('')
    const [lastName,setLastName]=useState('')
    const [userName,setUserName]=useState('')
    const [so_do,setso_do]=useState('')
    const [dateOfBirth,setDateOfBirth]=useState('')
    const [CNICNumber,setCNICNumber]=useState('')
    const [CNICExpirayDate,setCNICExpirayDate]=useState('')
    const [martialStatus,setMartialStatus]=useState('')
    const [familyMembers,setFamilyMembers]=useState('')
    const [homeAddress,setHomeAddress]=useState('')
    const [mobileNumber,setMobileNumber]=useState('')
    const [officeContact,setOfficeContact]=useState('')
    const [emergecnyContact,setEmergecnyContact]=useState('')
    const [photo,setPhoto]=useState(null)
   
    const [CNICFrontPhoto,setCNICFrontPhoto]=useState(null)
    const [CNICBackPhtot,setCNICBackPhtot]=useState(null)
    const [CVUpload,setCVUpload]=useState(null)
    const [department,setDepartment]=useState('')
    const [designation,setDesignation]=useState('')
    const [joiningDate,setJoiningDate]=useState('')
    const [startingSalary,setStartingSalary]=useState('')
    const [salaryAgreement,setSalaryAgreement]=useState('')
    const [contarctDate,setContarctDate]=useState('')
    const [contarctEndDate,setContarctEndDate]=useState('')
    const [userType,setUserType]=useState('')
    
    const [compnies,setCompnies]=useState([])
    const [compniesid,setCompniesid]=useState([])


    const [roles,setRoles]=useState([])
    const [permissions,setpermissions]=useState([])
    const [role,setRole]=useState('')
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
const token = localStorage.getItem('token')


const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    const checkboxId = parseInt(id, 10); // Convert id to a number
  
    setSelectedCheckboxes((prevSelected) => {
      if (checked) {
        // Add the checkbox ID to the array if checked
        return [...prevSelected, checkboxId];
      } else {
        // Remove the checkbox ID from the array if unchecked
        return prevSelected.filter((prevId) => prevId !== checkboxId);
      }
    });
  };

useEffect(()=>{
if(!token){
    navigate('/')
}
},[token])


  //get all roles
  const getAllRoles = async () => {
    
  
    try {
         const result = await AuthService.getRoles();
       
         
        
        if(result?.data?.status == 200){
        setRoles(result?.data?.data)
         
        
          
        } 
       
       
       
    } catch (error) {
      toast.error(error?.result?.data?.errors[0], {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        
      });
     
      
    }
    finally {
      setIsLoading(false)
    }
  };


  
  useEffect(()=>{
    getAllRoles()
  },[])

  //get all permissions
  const getAllPermissions = async () => {
    
  
    try {
         const result = await AuthService.getPermissions();
       
         
        
        if(result?.data?.status == 200){
        
          setpermissions(result?.data?.data)
        
          
        } 
       
       
       
    } catch (error) {
      toast.error(error?.result?.data?.errors[0], {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        
      });
     
      
    }
    finally {
      setIsLoading(false)
    }
  };


  
  useEffect(()=>{
    getAllPermissions()
  },[])


const getUserDetailFunction = async (id) => {
    setIsLoading(true);
    const result = await Services.getUserDetail(id);
    console.log(result);
    if (result?.status === 200) {
      setUser(result?.data?.data?.user);
      setFirstName(result?.data?.data?.user?.first_name)
      setLastName(result?.data?.data?.user?.last_name)
      setEmail(result?.data?.data?.user?.email)
    //   setPassword(result?.data?.data?.user?.password)

      setUserName(result?.data?.data?.user?.username)
      setso_do(result?.data?.data?.user?.so_do)
      setDateOfBirth(result?.data?.data?.user?.date_of_birth)
      setCNICNumber(result?.data?.data?.user?.cnic_number)

      setCNICExpirayDate(result?.data?.data?.user?.cnic_expiry_date)
      setMartialStatus(result?.data?.data?.user?.marital_status)
      setFamilyMembers(result?.data?.data?.user?.no_family_members)
      setHomeAddress(result?.data?.data?.user?.home_address)
      
      setMobileNumber(result?.data?.data?.user?.mobile_number)
      setOfficeContact(result?.data?.data?.user?.office_contact)
      setEmergecnyContact(result?.data?.data?.user?.emergency_contact_number)
      setDepartment(result?.data?.data?.user?.derpartment)
      setDesignation(result?.data?.data?.user?.designation)
      setJoiningDate(result?.data?.data?.user?.joining_date)
      setStartingSalary(result?.data?.data?.user?.starting_salary)
      setSalaryAgreement(result?.data?.data?.user?.salary_agrement)

      setContarctDate(result?.data?.data?.user?.contract_date)
      setContarctEndDate(result?.data?.data?.user?.contract_end_date)
      setUserType(result?.data?.data?.user?.user_type)
    //   setCompnies(result?.data?.data?.user?.companies)
    setCVUpload(result?.data?.data?.user?.upload_cv)
    setPhoto(result?.data?.data?.user?.photo)
    setCNICFrontPhoto(result?.data?.data?.user?.picture_cnic_front)
    setCNICBackPhtot(result?.data?.data?.user?.picture_cnic_back)
    setRole(result?.data?.data?.user?.roles[0]?.id)
    result?.data?.data?.user?.roles[0]?.permissions?.forEach((per) => {
      setSelectedCheckboxes((prevSelected) => {
        const newSelected = new Set(prevSelected); // Convert array to Set
        newSelected.add(parseInt(per.id)); // Add new ID
        return Array.from(newSelected); // Convert Set back to array
      });
    });



    } else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getUserDetailFunction(id);
  }, [id]);
const handlePhoto = (event) => {
    const selectedFile = event.target.files[0];
    setPhoto(selectedFile);
  };
  const handleCNICFrontPhoto = (event) => {
    const selectedFile = event.target.files[0];
    setCNICFrontPhoto(selectedFile);
  };
  const handleCNICBackPhoto = (event) => {
    const selectedFile = event.target.files[0];
    setCNICBackPhtot(selectedFile);
  };
  const handleCVUpload = (event) => {
    const selectedFile = event.target.files[0];
    setCVUpload(selectedFile);
  };
  const handleCompniesSelect = (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
    setCompniesid(selectedValues);
  };

  useEffect(()=>{
    getCompniesList()
},[])
    const getCompniesList = async ()=> {
        
            setIsLoading(true);
            const result = await Services.getCompnies()
            console.log(result)
            if (result?.status === 200) {
            
           setCompnies(result?.data?.data?.companies)
              
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    const updateUserFunction = async ()=> {
        if(email==""){
            toast.error("please enter email", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
    
        else {
            const formData = new FormData();
            if(email){
                formData.append("email",email)
            }
            // if(password){
            //     formData.append('password',password)
            // }
            if(firstName){
                formData.append('first_name',firstName)
            }
            if(lastName){
                formData.append('last_name',lastName)
            }
            if(userName){
                formData.append('username',userName)
            }
            if(so_do){
                formData.append('so_do',so_do)
            }
            if(dateOfBirth){
                formData.append('date_of_birth',dateOfBirth)
            }
            if(CNICNumber){
                formData.append('cnic_number',CNICNumber)
            }
            if(CNICExpirayDate){
                formData.append('cnic_expiry_date',CNICExpirayDate)
            }
            if(martialStatus){
                formData.append('marital_status',martialStatus)
            }
            if(familyMembers){
                formData.append('no_family_members',familyMembers)
            }
            if(homeAddress){
                formData.append('home_address',homeAddress)
            }
            if(mobileNumber){
                formData.append('mobile_number',mobileNumber)
            }
            if(officeContact){
                formData.append('office_contact',officeContact)
            }
            if(emergecnyContact){
                formData.append('emergency_contact_number',emergecnyContact)
            }
            if(photo){
                formData.append('photo',photo)
            }
            if(CNICFrontPhoto){
                formData.append('picture_cnic_front',CNICFrontPhoto)
            }
            if(CNICBackPhtot){
                formData.append('picture_cnic_back',CNICBackPhtot)
            }
            if(CVUpload){
                formData.append('upload_cv',CVUpload)
            }
            if(department){
                formData.append('derpartment',department)
            }
            if(designation){
                formData.append('designation',designation)
            }
            if(joiningDate){
                formData.append('joining_date',joiningDate)
            }
            if(startingSalary){
                formData.append('starting_salary',startingSalary)
            }
            if(salaryAgreement){
                formData.append('salary_agrement',salaryAgreement)
            }
            if(contarctDate){
                formData.append('contract_date',contarctDate)
            }
            if(contarctEndDate){
                formData.append('contract_end_date',contarctEndDate)
            }
           
            if(userType){
                formData.append('user_type',userType)
            }
            if(compniesid) {
                compniesid?.map((cid,id)=>formData.append(`companies_id[${id}]`,cid))
            }
            if(role){
                formData.append('role_id',role)
               }
               if(selectedCheckboxes){
                selectedCheckboxes?.map((id,index)=>{
                  formData.append(`permissions[${index}]`,id)
                })
               }
            setIsLoading(true);
            const result = await Services.UpdateUser(id,formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success("Data added sucessfuly", {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              setTimeout(()=>{
                navigate('/users')
              },1500)
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    
    }

  return (
    <div className='create-company dark-theme py-3'>
        
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
<div className="main-dashboard dark-theme">
    <Sidebar />
   <div className="right">
   <div className="row">
       
       <div className="col-12 col-md-12">
       <div className="create-company">
   <div className="form-wrapper">
        <h2 className="title-small text-center">Update User</h2>
    
        <div className="row">
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Email</label>
               <input type="text" className='input' value={email}  onChange={(e)=>setEmail(e.target.value)}  />
               </div>
           </div>
           {/* <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Password</label>
               <input type="password" className='input' value={password}  onChange={(e)=>setPassword(e.target.value)}   />
               </div>
           </div> */}
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">User Type</label>
               <select name="" className='input' value={userType} onChange={(e)=>setUserType(e.target.value)}>
               <option value="">Please select</option>
                   <option value="job holder">JOB HOLDER</option>
                   <option value="contractor">CONTRACTOR</option>
                   
               </select>
               </div>
           </div>
           { userType == "contractor" &&  <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Contract Date</label>
               <input type="date" className='input' value={contarctDate}  onChange={(e)=>setContarctDate(e.target.value)}   />
               </div>
           </div>
           }

           { userType == "contractor" &&
                <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Contract End Date</label>
                <input type="date" className='input' value={contarctEndDate}  onChange={(e)=>setContarctEndDate(e.target.value)}   />
                </div>
            </div>
           }
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Firts Name</label>
               <input type="text" className='input' value={firstName}  onChange={(e)=>setFirstName(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Last Name</label>
               <input type="text" className='input' value={lastName}  onChange={(e)=>setLastName(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Username</label>
               <input type="text" className='input' value={userName}  onChange={(e)=>setUserName(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Son/Daughter</label>
               <input type="text" className='input' value={so_do}  onChange={(e)=>setso_do(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Date of Birth</label>
               <input type="date" className='input' value={dateOfBirth}  onChange={(e)=>setDateOfBirth(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">CNIC</label>
               <input type="text" className='input' value={CNICNumber}  onChange={(e)=>setCNICNumber(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">CNIC Expiray Date</label>
               <input type="date" className='input' value={CNICExpirayDate}  onChange={(e)=>setCNICExpirayDate(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Martial Status</label>
               <select name="" className='input' value={martialStatus} onChange={(e)=>setMartialStatus(e.target.value)}>
               <option value="">Please select</option>
                   <option value="Single">Single</option>
                   <option value="Married">Married</option>
                   <option value="Divorced">Divorced</option>
               </select>
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Family Members</label>
               <input type="number" className='input' value={familyMembers}  onChange={(e)=>setFamilyMembers(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Home Address</label>
               <input type="text" className='input' value={homeAddress}  onChange={(e)=>setHomeAddress(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Mobile Number</label>
               <input type="text" className='input' value={mobileNumber}  onChange={(e)=>setMobileNumber(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Office Contact</label>
               <input type="text" className='input' value={officeContact}  onChange={(e)=>setOfficeContact(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Emergrency Contact</label>
               <input type="text" className='input' value={emergecnyContact}  onChange={(e)=>setEmergecnyContact(e.target.value)}   />
               </div>
           </div>
          
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Photo</label>
               <input type="file" accept=".jpg, .jpeg, .png, .gif" className='input'   onChange={handlePhoto}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">CNIC Front Photo</label>
               <input type="file" accept=".jpg, .jpeg, .png, .gif" className='input'  onChange={handleCNICFrontPhoto}   />
               </div>
           </div>
         
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">CNIC Back Photo</label>
               <input type="file" accept=".jpg, .jpeg, .png, .gif" className='input'   onChange={handleCNICBackPhoto}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Upload Cv</label>
               <input type="file" accept=".txt, .doc, .docx, .pdf" className='input'  onChange={handleCVUpload}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Department</label>
               <select name="" id="" value={department}   onChange={(e)=>setDepartment(e.target.value)}>
               <option value="">Select</option>
                <option value="Accounts & Finance">Accounts & Finance</option>
                <option value="Accounts & Recovery">Accounts & Recovery</option>
                <option value="Sales">Sales</option>
                <option value="Purchase">Purchase</option>
                <option value="Marketing">Marketing</option>
               </select>
               
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">designation</label>
               <input type="text" className='input' value={designation}  onChange={(e)=>setDesignation(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Joining date</label>
               <input type="date" className='input' value={joiningDate}  onChange={(e)=>setJoiningDate(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Starting Salary</label>
               <input type="text" className='input' value={startingSalary}  onChange={(e)=>setStartingSalary(e.target.value)}   />
               </div>
           </div>
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Salary Agreement</label>
               <input type="text" className='input' value={salaryAgreement}  onChange={(e)=>setSalaryAgreement(e.target.value)}   />
               </div>
           </div>
        
          
           <div className="col-12 col-md-6">
               <div className="form-group">
               <label htmlFor="">Select Compnies</label>
               <select name="" className='input'  value={compniesid} multiple onChange={handleCompniesSelect}>
               <option value="">Please select</option>
                   {compnies?.map((c)=><option value={c?.id}>{c?.company_name}</option>)}
                   
               </select>
               </div>
           </div>
           <div className="col-12 col-md-6 mb-4">
          <label htmlFor="">User role</label>
          <select className="input" value={role} onChange={(e)=>setRole(e.target.value)}>
            <option value="">Please select role</option>
            {roles?.map((rol)=><option value={rol?.id} >{rol?.name}</option>)}
          </select>
          
        </div>
          
        </div>

        <div className="title">Permissions</div>
   
   <div className="permissions-wrapper">
     
     {permissions?.map((per)=><div className="checkboxx" key={per?.id}>
     <input
         type="checkbox"
         id={parseInt(per.id)}
         onChange={handleCheckboxChange}
        checked={selectedCheckboxes?.includes(parseInt(per.id))}
       />
       <label htmlFor={parseInt(per.id)}>{per.name}</label>
     </div>)}

    
     
   </div>
       
        <div className="text-center mt-3">
           <button className='button'onClick={()=>updateUserFunction()} >Update now</button>
       </div>
   </div>
</div>
       </div>
   </div>
   </div>
</div>

    </div>
  )
}

export default UpdateUser