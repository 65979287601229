import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link } from 'react-router-dom';
import { IoReturnDownBack } from "react-icons/io5";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const CreateCompany = () => {
    const navigate = useNavigate()
    const [loading,setIsLoading]=useState(false)
    const [companyName,setCompanyName]=useState('')
    const [businesLine,setBusinesLine]=useState('')
    const [businessType,setBusinessType]=useState('')
    const [partnerName,setPartnerName]=useState('')
    const [partnerCNIC,setPartnerCNIC]=useState('')
    const [shopAddress,setShopAddress]=useState('')
    const [mobileNumber,setMobileNumber]=useState('')
    const [officePhone,setOfficePhone]=useState('')
    const [officePhone1,setOfficePhone1]=useState('')
    const [officePhone2,setOfficePhone2]=useState('')
    const [emergecnyContact,setEmergecnyContact]=useState('')
    const [fax,setFax]=useState('')
    const [fax2,setFax2]=useState('')
    const [fax3,setFax3]=useState('')
    const [headOfficeAddress,setHeadOfficeAddress]=useState('')
    const [isNationalTax,setIsNationalTax]=useState('no')
    const [nationalTax,setNationalTax]=useState('')
    const [isSalesTax,setIsSalesTax]=useState('no')
    const [salesTax,setSalesTax]=useState('')
    const [businesStartingDate,setBusinesStartingDate]=useState('')
    const [accountingYearStartDate,setAccountingYearStartDate]=useState('')
    const [accountingYearEndDate,setAccountingYearEndDate]=useState('')
    const [taxApplicableDate,setTaxApplicableDate]=useState('')
    // const [accountingMethod,setAccountingMethod]=useState('')
   
const token = localStorage.getItem('token')
useEffect(()=>{
if(!token){
    navigate('/')
}
},[token])

const handleTextEditor = (content, delta, source, editor) => {

    setBusinesLine(content);
};
    const addCompany = async ()=> {
        if(companyName==""){
            toast.error("please enter businessName", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
       else if(businesLine==''){
        toast.error("please enter businesLine", {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
        else {
            const formData = new FormData();
            if(companyName){
                formData.append("company_name",companyName)
            }
            if(businesLine){
                formData.append('business_line',businesLine)
            }
           
            if(shopAddress){
                formData.append('shop_address',shopAddress)
            }
            if(mobileNumber){
                formData.append('mobile_number',mobileNumber)
            }
            if(officePhone){
                formData.append('office_phone',officePhone)
            }
            if(officePhone1){
                formData.append('office_phone1',officePhone1)
            }
            if(officePhone2){
                formData.append('office_phone2',officePhone2)
            }
            if(emergecnyContact){
                formData.append('emergency_contact',emergecnyContact)
            }
            if(fax){
                formData.append('fax_number',fax)
            }
            if(fax2){
                formData.append('fax_number2',fax2)
            }
            if(fax3){
                formData.append('fax_number3',fax3)
            }
            if(headOfficeAddress){
                formData.append('head_office_address',headOfficeAddress)
            }
            if(businessType){
                formData.append('business_type',businessType)
            }
            if(partnerName){
                formData.append('partner_name',partnerName)
            }
            if(partnerCNIC){
                formData.append('partner_cnic',partnerCNIC)
            }
            if(nationalTax){
                formData.append('national_tax_registration',nationalTax)
            }
            if(salesTax){
                formData.append('sales_tax_registration',salesTax)
            }
            if(businesStartingDate){
                formData.append('business_starting_date',businesStartingDate)
            }
            if(accountingYearStartDate){
                formData.append('accounting_year_start',accountingYearStartDate)
            }
            if(accountingYearEndDate){
                formData.append('accounting_year_end',accountingYearEndDate)
            }
           
            // if(accountingMethod){
            //     formData.append('accounting_method',accountingMethod)
            // }
            if(taxApplicableDate){
                formData.append('tax_applicable_date',taxApplicableDate)
            }
         
           
            setIsLoading(true);
            const result = await Services.createCompany(formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success("Data added sucessfuly", {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              setTimeout(()=>{
                navigate('/compnies')
              },1500)
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    
    }

  return (
    <div className='create-company dark-theme py-3'>
   
    <Link to='/compnies' className="back">
        <IoReturnDownBack  /> Go to companies
    </Link>
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
<div className="container-fluid">
    <div className="row">
       
        <div className="col-12 col-md-12">
        <div className="create-company">
    <div className="form-wrapper">
         <h2 className="title-small text-center">Create company</h2>
     
         <div className="row">
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Company Name</label>
                <input type="text" className='input' value={companyName} placeholder='Company name' onChange={(e)=>setCompanyName(e.target.value)}  />
                </div>
            </div>
           
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Business Type</label>
                <select name="" value={businessType} onChange={(e)=>setBusinessType(e.target.value)}>
                <option value="">Please select</option>
                  <option value="sole proprietor">SOLE PROPRIETOR</option>
                  
                  <option value="partnership">PARTNERSHIP</option>
                  <option value="company">COMPANY</option>
                </select>
                </div>
            </div>
            {businessType == 'partnership' &&  <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Partner name</label>
                <input type="text" className='input' value={partnerName} placeholder='partner name' onChange={(e)=>setPartnerName(e.target.value)}  />
             
                </div>
            </div>}
            {businessType == 'partnership' &&  <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Partner CNIC</label>
                <input type="text" className='input' value={partnerCNIC} placeholder='partner name' onChange={(e)=>setPartnerCNIC(e.target.value)}  />
             
                </div>
            </div>}
            
            <div className="col-12 col-md-12">
                <div className="form-group">
                <label htmlFor="">Profile intro</label>
                <ReactQuill  value={businesLine} onChange={handleTextEditor} />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Shop address</label>
                <input type="text" className='input' value={shopAddress} onChange={(e)=>setShopAddress(e.target.value)} placeholder='Shop address'  />
                </div>
            </div>
           
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Mobile</label>
                <input type="text" className='input' placeholder='Mobile Number'  value={mobileNumber} onChange={(e)=>setMobileNumber(e.target.value)} />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Office Phone</label>
                <input type="text" className='input' placeholder='Office Contact'  value={officePhone} onChange={(e)=>setOfficePhone(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Office Phone 1</label>
                <input type="text" className='input' placeholder='Office Contact'  value={officePhone1} onChange={(e)=>setOfficePhone1(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Office Phone 2</label>
                <input type="text" className='input' placeholder='Office Contact'  value={officePhone2} onChange={(e)=>setOfficePhone2(e.target.value)}  />
                </div>
            </div>

            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Phone number</label>
                <input type="text" className='input' placeholder='Phone number'  value={emergecnyContact} onChange={(e)=>setEmergecnyContact(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Fax</label>
                <input type="text" className='input' placeholder='Fax'  value={fax} onChange={(e)=>setFax(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Fax2</label>
                <input type="text" className='input' placeholder='Fax2'  value={fax2} onChange={(e)=>setFax2(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Fax3</label>
                <input type="text" className='input' placeholder='Fax3'  value={fax3} onChange={(e)=>setFax3(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Head office address</label>
                <input type="text" className='input' placeholder='Office Contact'  value={headOfficeAddress} onChange={(e)=>setHeadOfficeAddress(e.target.value)}  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Business starting date</label>
                <input type="date" className='input' value={businesStartingDate} onChange={(e)=>setBusinesStartingDate(e.target.value)}   />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Tax Applicable Date</label>
                <input type="date" className='input' value={taxApplicableDate}  onChange={(e)=>setTaxApplicableDate(e.target.value)} placeholder='typer here'  />
                </div>
            </div>
            <div className="row">
             
         <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">NATIONAL TAX REGISTRATION (IF REGISTER)  </label>
                
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="d-flex gap-5">
                    <div className="radio-wrapper">
                    <input type="radio" name='nationaltex' checked={isNationalTax === 'yes'} value='yes' onChange={(e)=>setIsNationalTax(e.target.value)}  />
                    <label htmlFor="">Yes</label>
                    </div>
                    <div className="radio-wrapper">
                    <input type="radio" name='nationaltex' checked={isNationalTax === 'no'}  value='no' onChange={(e)=>setIsNationalTax(e.target.value)}  />
                    <label htmlFor="">No</label>
                    </div>
                    
                </div>
                </div>
          {isNationalTax === 'yes' &&  <div className="col-12 col-md-12">
                <div className="form-group">
                <label htmlFor="">NTN Number</label>
                <input type="text" className='input' value={nationalTax} onChange={(e)=>setNationalTax(e.target.value)} placeholder='NTN Number'  />
                </div>
                    </div>}     
                    <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">SALES TAX REGISTRATION (IF REGISTER)</label>
                
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="d-flex gap-5">
                    <div className="radio-wrapper">
                    <input type="radio" name='salestax' value='yes' checked={isSalesTax === 'yes'}  onChange={(e)=>setIsSalesTax(e.target.value)}  />
                    <label htmlFor="">Yes</label>
                    </div>
                    <div className="radio-wrapper">
                    <input type="radio" name='salestax' value='no' checked={isSalesTax === 'no'}  onChange={(e)=>setIsSalesTax(e.target.value)} />
                    <label htmlFor="">No</label>
                    </div>
                    
                </div>
                </div>
               {isSalesTax === 'yes' &&  <div className="col-12 col-md-12">
                <div className="form-group">
                <label htmlFor="">Sales Tax number</label>
                <input type="text" className='input' value={salesTax} onChange={(e)=>setSalesTax(e.target.value)} placeholder='Sales Tax number'  />
                </div>
                    </div>}
         </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Account period start date</label>
                <input type="date" className='input' value={accountingYearStartDate}  onChange={(e)=>setAccountingYearStartDate(e.target.value)} placeholder='Account period start date'  />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Account period end date</label>
                <input type="date" className='input' value={accountingYearEndDate}  onChange={(e)=>setAccountingYearEndDate(e.target.value)} placeholder='Account period end date'  />
                </div>
            </div>
            {/* <div className="col-12 col-md-12">
                <div className="form-group">
                <label htmlFor="">Account Method</label>
                <input type="text" className='input' value={accountingMethod}  onChange={(e)=>setAccountingMethod(e.target.value)} placeholder='typer here'  />
                </div>
            </div> */}
         
           
           
           
         </div>
        
         <div className="text-center mt-3">
            <button className='button' onClick={()=>addCompany()}>Create now</button>
        </div>
    </div>
 </div>
        </div>
    </div>
</div>

    </div>
  )
}

export default CreateCompany