import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link,useParams } from 'react-router-dom';
import { IoReturnDownBack } from "react-icons/io5";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ProductBar from '../ProductBar';

import Origins from '../../helpers/Helpers'

const UpdateProductToList = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const [modal,setModal]=useState(false)
    const [product,setProduct]=useState({})
    const [productDetail,setProductDetail]=useState({})
    const [loading,setIsLoading]=useState(false)
    const [productId,setProductId]=useState('')
    const [products,setProducts] =useState([])
    const [modelId,setModelId]=useState('')
   
    const [brandId,setBrandId]=useState('')
    const [brands,setBrands] =useState([])
    const [catId,setCatId]=useState('')
    const [description,setDescription]=useState('')
    const [categoryId,setCategoryId]=useState('')
    const [unitOfMeasure,setUnitOfMeasure]=useState('')
    const [productType,setProductType]=useState('')
    const [productMarkete,setProductMarkete]=useState('')
    const [origin,setOrigin]=useState('')
    const [warrenty,setWarrenty]=useState('')
    const [warrentyDuration,setWarrentyDuration]=useState('')
    const [isExpiry,setIsExpiry]=useState('Yes')
    const [expiryAlertDate,setExpiryAlertDate] = useState('')
    const [productExpirayDate,setProductExpirayDate]=useState('')
    const [productCostingMethod,setProductCostingMethod]=useState('')
    const [picture,setPicture]=useState('')
    const [technicalSheet,setTechnicalSheet]=useState('')
    const [openingStock,setOpeningStock]=useState('')
    const [costOfPurchase,setCostOfPurchase]=useState('')
    const [vendorName,setVendorName]=useState('')
    const [minimumStock,setMinimumStock]=useState('')
    const [customHSCode,setCustomHSCode]=useState('')
    const [partsAvailibility,setPartsAvailibility]=useState('')
   //create product states
   const [categories,setCategories] =useState([])
    const [name,setName]=useState('')
    const [category,setCategory]=useState('')
    const [image,setImage]=useState(null)
    const [descriptionc,setDescriptionc]=useState('')
    
    const [unitOfMeasurec,setUnitOfMeasurec]=useState('')
   
 
//handle uplpad photo
const handleUploadPicture = (e)=>{
    const file = e.target.files[0]
    setPicture(file)
}

//handle sheet upload
const handleUploadSheet = (e)=>{
    const file = e.target.files[0]
    setTechnicalSheet(file)
}
const handlePhoto = (event) => {
    const selectedFile = event.target.files[0];
    setImage(selectedFile);
  };
   
const token = localStorage.getItem('token')
const handleTextEditor = (content, delta, source, editor) => {

    setDescription(content);
};
const handleTextEditorc = (content, delta, source, editor) => {

    setDescriptionc(content);
};
const handleRemarks = (content, delta, source, editor) => {

    setWarrentyDuration(content);
};

useEffect(()=>{
if(!token){
    navigate('/')
}
},[token])




//product detail function

//handle add product function

 //get product define detail

 //product detail function
 const getProductDetailApi = async (idd) => {
    setIsLoading(true);
    const result = await Services.getProductDetail(idd);
    console.log(result);
    if (result?.status === 200) {
      setProduct(result?.data?.data?.product);
      setDescriptionc(result?.data?.data?.product?.description)
      setCategoryId(result?.data?.data?.product?.category_id)
      setUnitOfMeasure(result?.data?.data?.product?.unit_of_measure)
    } else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getProductDetailApi(productId);
  }, [productId]);
  //get categories list
  const getProductDetailFunction = async (id) => {
    setIsLoading(true);
    const result = await Services.getProductListDetail(id);
    console.log(result);
    if (result?.status === 200) {
        setProductDetail(result?.data?.data?.products_to_list)
        setCatId(result?.data?.data?.products_to_list?.category_id);
      setProductId(result?.data?.data?.products_to_list?.product_id);
      setDescription(result?.data?.data?.products_to_list?.description)
      setCategoryId(result?.data?.data?.products_to_list?.category_id)
      setUnitOfMeasure(result?.data?.data?.products_to_list?.unit_of_measure)
      setBrandId(result?.data?.data?.products_to_list?.brand_id)
      setModelId(result?.data?.data?.products_to_list?.model_id)
      setOrigin(result?.data?.data?.products_to_list?.origin)

      setProductType(result?.data?.data?.products_to_list?.product_type);
      setProductMarkete(result?.data?.data?.products_to_list?.product_markete)
      setWarrenty(result?.data?.data?.products_to_list?.warrenty)
      setWarrentyDuration(result?.data?.data?.products_to_list?.warrenty_duration)
      setProductExpirayDate(result?.data?.data?.products_to_list?.product_expiray_date)
      setExpiryAlertDate(result?.data?.data?.products_to_list?.expiry_alert_date)
      setProductCostingMethod(result?.data?.data?.products_to_list?.product_costing_method)
      setOpeningStock(result?.data?.data?.products_to_list?.opening_stock)
       

      setCostOfPurchase(result?.data?.data?.products_to_list?.cost_of_purchase);
      setVendorName(result?.data?.data?.products_to_list?.vendor_name)
      setMinimumStock(result?.data?.data?.products_to_list?.minimum_stock)
      setCustomHSCode(result?.data?.data?.products_to_list?.custom_hs_code)
      setPartsAvailibility(result?.data?.data?.products_to_list?.parts_availability)
      
      
      
    } else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getProductDetailFunction(id);
  }, [id]);
const getCategoriesList = async ()=> {
            
    setIsLoading(true);
    const result = await Services.getCategories()
    
    if (result?.status === 200) {
    
   setCategories(result?.data?.data?.categories)
   console.log(result?.data?.data?.categories)
    }
    else {
        toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
    setIsLoading(false);
}

useEffect(()=>{
    getCategoriesList()
},[])
  //handle add product modal form

//handle add product function

 
    const updateProductFunction = async ()=> {
        if(productId==""){
            toast.error("please select product id", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
      
        if(brandId==""){
            toast.error("please select brand id", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
      
        else {
            const formData = new FormData();
            if(productId){
                formData.append("product_id",productId)
            }
            if(brandId){
                formData.append('brand_id',brandId)
            }
            if(productId) {
                formData.append('is_publish', 1)
            }
          
            if(modelId){
                formData.append('model_id',modelId)
            }
            if(description){
                formData.append('description',description)
            }
          
            if(categoryId){
                formData.append('category_id',categoryId)
            }
            if(unitOfMeasure){
                formData.append('unit_of_measure',unitOfMeasure)
            }

            if(productType){
                formData.append('product_type',productType)
            }
            if(productMarkete){
                formData.append('product_markete',productMarkete)
            }
          
            if(origin){
                formData.append('origin',origin)
            }
            if(warrenty){
                formData.append('warrenty',warrenty)
            }


            if(warrentyDuration){
                formData.append('warrenty_duration',warrentyDuration)
            }
            if(expiryAlertDate){
                formData.append(' expiry_alert_date',expiryAlertDate)
            }
            if(productExpirayDate){
                formData.append('product_expiray_date',productExpirayDate)
            }
          
            if(productCostingMethod){
                formData.append('product_costing_method',productCostingMethod)
            }
            if(picture){
                formData.append('picture',picture)
            }

            if(technicalSheet){
                formData.append('technical_sheet',technicalSheet)
            }
            // if(openingStock){
            //     formData.append('opening_stock',openingStock)
            // }
          
            // if(costOfPurchase){
            //     formData.append('cost_of_purchase',costOfPurchase)
            // }
            // if(vendorName){
            //     formData.append('vendor_name',vendorName)
            // }

            // if(minimumStock){
            //     formData.append('minimum_stock',minimumStock)
            // }
            if(customHSCode){
                formData.append('custom_hs_code',customHSCode)
            }
            if(partsAvailibility){
                formData.append('parts_availability',partsAvailibility)
            }
            
         
            setIsLoading(true);
            const result = await Services.updateProductToListAPi(id,formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success("Product added sucessfuly", {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              setBrandId('')
              setProductId('')
              setModelId('')
              
              navigate('/products-list')
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    }
    

    

    //get Products list
  
        const getProductList = async ()=> {
            
                setIsLoading(true);
                const result = await Services.getProducts()
                
                if (result?.status === 200) {
                
               setProducts(result?.data?.data?.products)
               
                }
                else {
                    toast.error(result?.data?.message, {
                        autoClose: 2000,
                        pauseOnHover: true,
                        draggable: true,
                        position: toast.POSITION.TOP_CENTER,
                      });
                }
                setIsLoading(false);
            }

            useEffect(()=>{
                getProductList()
            },[])
    ///Brands functions and api calls


    const getBrandsList = async (catid)=> {
            
        setIsLoading(true);
        const result = await Services.getCategoryBrands(catid)
        
        if (result?.status === 200) {
        
       setBrands(result?.data?.data?.brands)
       
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        getBrandsList(catId)
    },[catId])
 


  return (
    <div className='create-company dark-theme py-3'>
    <ProductBar />
<Link to='/products' className="back">
    <IoReturnDownBack  /> Go to products
</Link>

        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}


<div className="container-fluid">
    <div className="row">
      
        <div className="col-12 col-md-12">
        <div className="create-company">
    <div className="form-wrapper">
         <h2 className="title-small text-center">Product Profile for inventory(update) </h2>
     
         <div className="row">
          
           
            
          
            <div className="col-12 col-md-6">
               
                <div className="form-group">
                <label htmlFor="">Select product </label>
                 <div className="categories-wrapper">
                 <select value={productId} disabled onChange={(e)=>setProductId(e.target.value)}>
                   
                   <option value="">Please select</option>
                       {products?.map((pro)=> <option key={pro?.id} value={pro?.id}>{pro?.name}</option>)}
                   </select>
                   {/* <button className="button" onClick={()=>{setModal((pre)=>!pre);setDescriptionc('')}}>Add new</button> */}
                 </div>
                
             
                </div>
                
                </div>
                <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Select Brand </label>
               
               <select value={brandId} onChange={(e)=>setBrandId(e.target.value)}>
                   <option value="">Please select</option>
                   {brands?.map((brand)=> <option key={brand?.id} value={brand?.id}>{brand?.name}</option>)}
               </select>
            
               </div>
               
               </div>
               <div className="col-12 col-md-6">
               
               <div className="form-group ">
               <label htmlFor="">Enter model </label>
               <div className="categories-wrapper">
                <input type="text" className="input" value={modelId} onChange={(e)=>setModelId(e.target.value)} />
               
             </div>
               </div>
               
               </div>
               <div className="col-12 col-md-6">
               <div className="col-12 col-md-6">
               <div className="form-group">
                <label htmlFor="">Unit of measure</label>
                <select className='input' value={unitOfMeasure} onChange={(e)=>setUnitOfMeasure(e.target.value)}>
                <option value="">Please select</option>
                    <option value="KG">KG</option>
                    <option value="PKT">PKT</option>
                    <option value="PC">PC</option>
                    <option value="PC's">PC's</option>
                    <option value="NOS">NOS</option>
                    <option value="FT">FT</option>
                    <option value="MTR">MTR</option>
                    <option value="SQFT">SQFT</option>
                    <option value="SQMT">SQMT</option>
                    <option value="g">g</option>
                    <option value="Gm">Gm</option>
                    <option value="MM">MM</option>
                    <option value="Inch">Inch</option>
                    
                </select>
                </div>
               
               
               </div>
               
               
               </div>
               <div className="col-12">
               <div className="form-group">
               <label htmlFor="">Technical specifications </label>
               
               <ReactQuill  value={description ? description : descriptionc} onChange={handleTextEditor} />
            
               </div>
              
               
               
               </div>
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Product Type </label>
               
               <select value={productType} onChange={(e)=>setProductType(e.target.value)}>
                  
               <option value="">Please select</option>
               <option value="stock">Stock</option>
                   <option value="trade">Trade</option>
                   <option value="import">Import</option>
               </select>
            
               </div>
               
               </div>
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Product Markete Type </label>
               
               <select value={productMarkete} onChange={(e)=>setProductMarkete(e.target.value)}>
                  
               <option value="">Please select</option>
                   <option value="Local">Local</option>
                   <option value="Import">Import</option>
                   <option value="Import & Local">Import & Local</option>
                   <option value="Manufacturing">Manufacturing</option>
               </select>
            
               </div>
               
               </div>
               <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Origion of product</label>
                <select value={origin} onChange={(e)=>setOrigin(e.target?.value)}>
                <option value="">Please select</option>
                    {Origins?.map((ori)=> <option value={ori}>{ori}</option>)}
                </select>
                </div>
            </div>
            <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Warrenty </label>
               
               <select value={warrenty} onChange={(e)=>setWarrenty(e.target.value)}>
                  
               <option value="">Please select</option>
                   <option value="Yes">Yes</option>
                   <option value="No">No</option>
                   
               </select>
            
               </div>
               
               </div>
               {warrenty == 'Yes' && <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Warrenty remarks </label>
               
               <ReactQuill  value={warrentyDuration} onChange={handleRemarks} />
                  
               
            
               </div>
               
               </div>}
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Expiry Date </label>
               
               <select value={isExpiry} onChange={(e)=>setIsExpiry(e.target.value)}>
                  
               <option value="">Please select</option>
                   <option value="Yes">Yes</option>
                   <option value="No">No</option>
                   
               </select>
            
               </div>
               
               </div>
               {isExpiry == "Yes" && <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Product Expiry Date </label>
               
               <input type='date' value={productExpirayDate} className='input' onChange={(e)=>setProductExpirayDate(e.target.value)} />
                  
               
            
               </div>
               
               </div>}
               {isExpiry == "Yes" &&
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Product Expiry Alert Date </label>
               
               <input type='date' value={expiryAlertDate} className='input' onChange={(e)=>setExpiryAlertDate(e.target.value)} />
                  
               
            
               </div>
               
               </div>
               }
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Product Costing Method </label>
               
               <select value={productCostingMethod} onChange={(e)=>setProductCostingMethod(e.target.value)}>
                  
               <option value="">Please select</option>
               <option value="FIFO">FIFO</option>
                   <option value="LIFO">LIFO</option>
                  
                   <option value="Average">Average</option>
                   
               </select>
            
               </div>
               
               </div>
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Upload Picture </label>
               
               <input type='file'  className='input' onChange={handleUploadPicture} />
                  
               
            
               </div>
               
               </div>
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Upload Technical Sheet </label>
               
               <input type='file'  className='input' onChange={handleUploadSheet} />
                  
               
            
               </div>
               
               </div>
               {/* <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Opening Stock </label>
               
               <input type='text'  value={openingStock} className='input' onChange={(e)=>setOpeningStock(e.target.value)} />
                  
               
            
               </div>
               
               </div> */}
               {/* <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Cost Of Purchase </label>
               
               <input type='text'  value={costOfPurchase} className='input' onChange={(e)=>setCostOfPurchase(e.target.value)} />
                  
               
            
               </div>
               
               </div> */}
               {/* <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Vendor Name </label>
               
               <input type='text'  value={vendorName} className='input' onChange={(e)=>setVendorName(e.target.value)} />
                  
               
            
               </div>
               
               </div> */}
               {/* <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Minimun Stock </label>
               
               <input type='text'  value={minimumStock} className='input' onChange={(e)=>setMinimumStock(e.target.value)} />
                  
               
            
               </div>
               
               </div> */}
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Custom HS Code </label>
               
               <input type='text'  value={customHSCode} className='input' onChange={(e)=>setCustomHSCode(e.target.value)} />
                  
               
            
               </div>
               
               </div>
               <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Parts availibilty </label>
               
               <select value={partsAvailibility} onChange={(e)=>setPartsAvailibility(e.target.value)}>
                  
               <option value="">Please select</option>
                   <option value="Yes">Yes</option>
                   <option value="No">No</option>
                   <option value="On demand">On demand</option>
                   
               </select>
            
               </div>
               
               </div>
            </div>
          
        </div>
       
        
         <div className="text-center m-3 ">
            <button className='button'onClick={()=>updateProductFunction()} >Update product now</button>
        </div>
    </div>
 </div>
        </div>
    </div>
</div>

    
  )
}

export default UpdateProductToList