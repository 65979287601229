import React from 'react'
import { Link } from 'react-router-dom'

const ProductBar = () => {
  return (
    <div className='product-bar'>
        
         <Link className='button' to="/categories">Categories</Link>
         <Link className='button' to="/brands">Brands</Link>
         
        <Link className='button' to="/products">Products</Link>
        <Link className='button' to="/draft-products">Draft Products</Link>
        <Link className='button' to="/create-product">Design Product</Link>
        <Link className='button' to="/draft-products-list">Draft Listed product</Link>
        <Link className='button' to="/products-list">Listed product</Link>
        
        <Link className='button' to="/add-product-to-list">Generate product for inventory</Link>
        
        
        
        
    </div>
  )
}

export default ProductBar