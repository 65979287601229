import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link,useParams } from 'react-router-dom';
import userAvatar from '../../images/user.png'

import { IoReturnDownBack } from "react-icons/io5";
import Origins from '../../helpers/Helpers'

const UpdateBrand = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const [loading,setIsLoading]=useState(false)
    const [modal,setModal]=useState(false)
    const [name,setName]=useState('')
  
    const [categories,setCategories] =useState([])
    const [categoriesid,setCategoriesid]=useState([])
  
    const [origin,setOrigin]=useState('')
    
  

    const [cname,setCName]=useState('')
    
 
const token = localStorage.getItem('token')


useEffect(()=>{
if(!token){
    navigate('/')
}
},[token])

//create category section

const handleCategoriesSelect = (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
    setCategoriesid(selectedValues);
  };
   


 


 
    const addCategory = async ()=> {
        if(cname==""){
            toast.error("please enter name", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
      
        else {
            const formData = new FormData();
            if(cname){
                formData.append("name",cname)
            }
          
      
           
            setIsLoading(true);
            const result = await Services.createCategory(formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success("Category added sucessfuly", {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              getCategoriesList()
              setModal(false)
              
              setCName('')
             
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    }
    

    //get categories list
  
        const getCategoriesList = async ()=> {
            
                setIsLoading(true);
                const result = await Services.getCategories()
                console.log(result)
                if (result?.status === 200) {
                
               setCategories(result?.data?.data?.categories)
                  
                }
                else {
                    toast.error(result?.data?.message, {
                        autoClose: 2000,
                        pauseOnHover: true,
                        draggable: true,
                        position: toast.POSITION.TOP_CENTER,
                      });
                }
                setIsLoading(false);
            }

            useEffect(()=>{
                getCategoriesList()
            },[])
    ///Brands functions and api calls




 
    const updateBrandApi = async ()=> {
        if(name==""){
            toast.error("please enter name", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
     
    
        else if (origin === ''){
            toast.error("please select origin of brand", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
            });
        }
        else {
            const formData = new FormData();
            if(name){
                formData.append("name",name)
            }
            if(categoriesid) {
                categoriesid?.map((cid,id)=>formData.append(`category_id[${id}]`,cid))
            }
            
          
          
            if(origin){
                formData.append('origin',origin)
            }
           
           
            setIsLoading(true);
            const result = await Services.updateBrand(id,formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success("Brand updated sucessfuly", {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              setTimeout(()=>{
                navigate('/brands')
              },1500)
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    }
    
    const getBrandApi = async (id) => {
        setIsLoading(true);
        const result = await Services.getBrandDetail(id);
        console.log(result);
        if (result?.status === 200) {
          setName(result?.data?.data?.name)
          
          setOrigin(result?.data?.data?.origin)
          result?.data?.data?.categories?.map((cat)=>setCategoriesid((pre)=> [...pre,cat?.id]))
          
        } else {
          toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setIsLoading(false);
      };
      useEffect(() => {
        getBrandApi(id);
      }, [id]);

  return (
    <div className='create-company dark-theme py-3'>
   
    <Link to='/brands' className="back">
        <IoReturnDownBack  /> Go to Brands
    </Link>
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}

    {modal && <div className='modal-fixed'>
        <div className="form-modal">
            <div className="icon">
            <i class="fa-regular fa-circle-xmark" onClick={()=>setModal(false)}></i>
            </div>
            <p className="title">
                Create Category
            </p>

            <div className="form-wrapper">
      
     
         <div className="row">
            <div className="col-12 col-md-12">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={cname}  onChange={(e)=>setCName(e.target.value)}  />
                </div>
            </div>
           
            
          
            
            
           
         </div>
        
         <div className="text-center mt-3">
            <button className='button'onClick={()=>addCategory()} >Add now</button>
        </div>
    </div>
            </div></div>}
<div className="container-fluid">
    <div className="row">
      
        <div className="col-12 col-md-12">
        <div className="create-company">
    <div className="form-wrapper">
         <h2 className="title-small text-center">Update Brand</h2>
     
         <div className="row">
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={name}  onChange={(e)=>setName(e.target.value)}  />
                </div>
            </div>
           
            
          
            
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Origion of brand</label>
                <select value={origin} onChange={(e)=>setOrigin(e.target?.value)}>
                <option value="">Please select</option>
                    {Origins?.map((ori)=> <option value={ori}>{ori}</option>)}
                </select>
                </div>
            </div>
            <div className="col-12 col-md-6">
               
               <div className="form-group">
               <label htmlFor="">Category </label>
               <div className="categories-wrapper">
               <select value={categoriesid} multiple onChange={handleCategoriesSelect}>
               <option value="">Please select</option>
                   {categories?.map((cat)=> <option key={cat?.id} value={cat?.id}>{cat?.name}</option>)}
               </select>
             <button onClick={()=>setModal((pre)=> !pre)}>Add category</button>
               </div>
               
               </div>
              
           </div>
           
           
         </div>
        
         <div className="text-center mt-3">
            <button className='button'onClick={()=>updateBrandApi()} >Update now</button>
        </div>
    </div>
 </div>
        </div>
    </div>
</div>

    </div>
  )
}

export default UpdateBrand