import React, { useEffect, useState,useContext } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate,Link,useParams } from 'react-router-dom';
import Sidebar from '../Sidebar';
import { IoReturnDownBack } from "react-icons/io5";
import ProductBar from '../ProductBar';
import ReactQuill from 'react-quill';
import { AuthContext } from '../authcontext/AuthContext';
import 'react-quill/dist/quill.snow.css';

const UpdateProduct = () => {
    const {currentUser} = useContext(AuthContext)
    const { id } = useParams();
    const navigate = useNavigate()
    const [product, setProduct] = useState({});
    const [loading,setIsLoading]=useState(false)
    const [modal,setModal]=useState(false)
    const [categories,setCategories] =useState([])
    const [name,setName]=useState('')
    const [category,setCategory]=useState('')
    const [image,setImage]=useState(null)
    const [description,setDescription]=useState('')
    
    const [unitOfMeasure,setUnitOfMeasure]=useState('')
    const [fields, setFields] = useState([{ name: '', label: '', input_value: '' }]);
    const [cname,setCName]=useState('')
    

    const [cimage,setCImage]=useState(null)

  
 
const token = localStorage.getItem('token')
console.log(currentUser)

useEffect(()=>{
if(!token){
    navigate('/')
}
},[token])
const getProductDetailFunction = async (id) => {
    setIsLoading(true);
    const result = await Services.getProductDetail(id);
    console.log(result);
    if (result?.status === 200) {
      setProduct(result?.data?.data?.product);
      setName(result?.data?.data?.product?.name)
      setCategory(result?.data?.data?.product?.category_id)
      setUnitOfMeasure(result?.data?.data?.product?.unit_of_measure)
      setDescription(result?.data?.data?.product?.description)
    } else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getProductDetailFunction(id);
  }, []);
const handleTextEditor = (content, delta, source, editor) => {

    setDescription(content);
};
//create category section


const handleAddField = () => {
    setFields([...fields, { name: '', label: '', input_value: '' }]);
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const handleChange = (index, field, value) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = value;
    setFields(updatedFields);
  };

//add category

const addCategory = async ()=> {
    if(cname==""){
        toast.error("please enter name", {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
    }
  
    else {
        const formData = new FormData();
        if(cname){
            formData.append("name",cname)
        }
        
        
      
        if(cimage){
            formData.append('image',cimage)
        }
  
       
        setIsLoading(true);
        const result = await Services.createCategory(formData)
        console.log(result)
        if (result?.status === 200) {
       
        toast.success("Category added sucessfuly", {
            autoClose: 1000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
          getCategoriesList()
          setModal(false)
          setCImage(null)
          setCName('')
        
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    }
}

//handle

const handlePhotoCategory = (event) => {
    const selectedFile = event.target.files[0];
    setCImage(selectedFile);
  };
//handle category

const handleCategory = (e)=>{
  
    setCategory(e.target.value)
    setFields([])
    const matched = categories?.find((item)=> item.id === parseInt(e.target.value))
    if (matched) {
         
        matched?.dynamic_form_fields?.map((field)=> setFields((prev)=>[...prev,{name:field?.name,label:field?.label,input_value:field?.input_value}]))
      } else {
        console.log("no id");
      }
}
 


 
    const updateProductApi = async ()=> {
        if(name==""){
            toast.error("please enter name", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
      
        else {
            const formData = new FormData();
            if(name){
                formData.append("name",name)
            }
            if(name){
                formData.append("is_publish",1)
            }
            if(category){
                formData.append('category_id',category)
            }
            
          
            if(image){
                formData.append('image',image)
            }
            if(description){
                formData.append('description',description)
            }
            if(unitOfMeasure){
                formData.append('unit_of_measure',unitOfMeasure)
            }
            if(fields){
                formData.append('dynamic_form_fields' , JSON.stringify(fields))
            }
          
      
         
            setIsLoading(true);
            const result = await Services.updateProduct(id,formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success("Product updated sucessfuly", {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              getCategoriesList()
              setName('')
              setUnitOfMeasure('')
              setCategory('')
              setFields([])
              setDescription('')
              navigate('/products')
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    }
    

    //get categories list
  
        const getCategoriesList = async ()=> {
            
                setIsLoading(true);
                const result = await Services.getCategories()
                
                if (result?.status === 200) {
                
               setCategories(result?.data?.data?.categories)
               console.log(result?.data?.data?.categories)
                }
                else {
                    toast.error(result?.data?.message, {
                        autoClose: 2000,
                        pauseOnHover: true,
                        draggable: true,
                        position: toast.POSITION.TOP_CENTER,
                      });
                }
                setIsLoading(false);
            }

            useEffect(()=>{
                getCategoriesList()
            },[])
    ///Brands functions and api calls

const handlePhoto = (event) => {
    const selectedFile = event.target.files[0];
    setImage(selectedFile);
  };
 


 
    


  return (
    <div className='create-company dark-theme py-3'>
        <ProductBar />
    <Link to='/dashboard' className="back">
        <IoReturnDownBack  /> Go to dashboard
    </Link>
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}

    {modal && <div className='modal-fixed'>
        <div className="form-modal">
            <div className="icon">
            <i class="fa-regular fa-circle-xmark" onClick={()=>setModal(false)}></i>
            </div>
            <p className="title">
                Create Category
            </p>

            <div className="form-wrapper">
      
     
         <div className="row">
            <div className="col-12 col-md-12">
                <div className="form-group">
                <label htmlFor="">Name</label>
                <input type="text" className='input' value={cname}  onChange={(e)=>setCName(e.target.value)}  />
                </div>
            </div>
        
            
            
           
         </div>
        
         <div className="text-center mt-3">
            <button className='button'onClick={()=>addCategory()} >Add now</button>
        </div>
    </div>
            </div></div>}

<div className="container-fluid">
    <div className="row">
       
        <div className="col-12 col-md-12">
        <div className="create-company">
    <div className="form-wrapper">
         <h2 className="title-small text-center">Update Product</h2>
     
         <div className="row">
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Product Name</label>
                <input type="text" className='input' value={name}  onChange={(e)=>setName(e.target.value)}  />
                </div>
            </div>
           
            
            {/* <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Photo</label>
                <input type="file" accept=".jpg, .jpeg, .png, .gif" className='input'   onChange={handlePhoto}   />
                </div>
            </div> */}
            <div className="col-12 col-md-6">
               
                <div className="form-group">
                <label htmlFor="">Category </label>
                <div className="categories-wrapper">
                <select className='input' value={category} onChange={handleCategory}>
                <option value="">Please select</option>
                    {categories?.map((cat)=> <option key={cat?.id} value={cat?.id}>{cat?.name}</option>)}
                </select>
              <button onClick={()=>setModal((pre)=> !pre)}>Add category</button>
                </div>
                
                </div>
               
            </div>
           
            <div className="col-12 col-md-6">
                <div className="form-group">
                <label htmlFor="">Unit of measure</label>
                <select className='input' value={unitOfMeasure} onChange={(e)=>setUnitOfMeasure(e.target.value)}>
                <option value="">Please select</option>
                    <option value="KG">KG</option>
                    <option value="PKT">PKT</option>
                    <option value="PC">PC</option>
                    <option value="PC's">PC's</option>
                    <option value="NOS">NOS</option>
                    <option value="FT">FT</option>
                    <option value="MTR">MTR</option>
                    <option value="SQFT">SQFT</option>
                    <option value="SQMT">SQMT</option>
                    <option value="g">g</option>
                    <option value="Gm">Gm</option>
                    <option value="MM">MM</option>
                    <option value="Inch">Inch</option>
                    
                </select>
                </div>
            </div>
            <div className="col-12">
              
                <div className="form-group">
               <label htmlFor="">Required technical specifications </label>
               
               <ReactQuill  value={description} onChange={handleTextEditor} />
            
               </div>
            </div>
        </div>
        
        
         <div className="text-center m-3 ">
            <button className='button'onClick={()=>updateProductApi()} >Save</button>
        </div>
    </div>
 </div>
        </div>
    </div>
</div>

    </div>
  )
}

export default UpdateProduct